/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import classnames from "classnames";

import AsyncSelect from "react-select/async";
import { WindowedMenuList } from "react-windowed-select";
import { Dropdown } from "react-bootstrap";

import _isEmpty from "lodash/isEmpty";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _compact from "lodash/compact";
import _uniq from "lodash/uniq";

import { Checkbox } from "@onlinesales-ai/checkbox-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { Pill } from "@onlinesales-ai/pill-v2";
import {
  formattedNumber,
  useControlState,
  useOnClickOutside,
} from "@onlinesales-ai/util-methods-v2";
import { Text } from "@onlinesales-ai/label-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { ShadowScroll } from "@onlinesales-ai/utils-components-v2";

import BaseDropdown, { defaultGetValue, defaultGetMultipleValue } from "./BaseDropdown";
import { components } from "./index";

import "./UniversalDropdown.less";
import { EmptyMessage } from "@onlinesales-ai/message-card-v2";

const DropdownIndicator = () => <span className="icon icon-search-2 search-icon" />;

const LeftRightText = ({ data, innerProps, isDisabled, isVertical, isSelected }) => {
  const { label, rightText, rightTextProps, disabledTooltip, tooltip } = data;
  if (label || rightText) {
    return (
      <WithTooltip title={isDisabled ? disabledTooltip : tooltip}>
        <div
          {...innerProps}
          className={`custom-option-wrapper ${isDisabled ? "disabled" : ""} ${
            isVertical ? "d-flex-column" : "d-align-between"
          } ${isSelected ? "selected" : ""}`}
        >
          <span className="left">
            <Text className="name" size="small" type="primary">
              {label}
            </Text>
          </span>
          {rightText && rightTextProps?.type === "number" ? (
            <Text
              type="tertiary"
              italic
              className="right nowrap-text d-align-center gap-5"
              size="small"
            >
              {formattedNumber(rightText, rightTextProps)}
            </Text>
          ) : (
            <>
              {typeof rightText === "object" ? (
                rightText
              ) : (
                <Text type="tertiary" italic className="right nowrap-text" size="small">
                  {`${rightText}`}
                </Text>
              )}
            </>
          )}
        </div>
      </WithTooltip>
    );
  }

  return null;
};

const TopBottomTemplate = ({
  data,
  innerProps,
  isDisabled,
  isVertical,
  isSelected,
  optionsClassName,
}) => {
  const { topText, bottomText, tooltip, disabledTooltip } = data;

  return (
    <WithTooltip title={isDisabled ? disabledTooltip : tooltip}>
      <div
        {...innerProps}
        className={`custom-option-wrapper ${isDisabled ? "disabled" : ""} ${
          isVertical ? "d-flex-column" : "d-align-between"
        } ${isSelected ? "selected" : ""} ${optionsClassName}`}
      >
        <div>
          <div className="top-text d-align-center">{topText}</div>
          <div className="bottom-text">{bottomText}</div>
        </div>
      </div>
    </WithTooltip>
  );
};

const MenuListWithButton = (props) => {
  return (
    <components.MenuList {...props}>
      <>
        <>{props.children}</>
        <div className="dropdown-button-wrapper text-center">
          <Button
            icon={props.icon}
            link
            onClick={props.onClickActionButton}
            className={`ml-0 ${props.btnClassName}`}
          >
            {props.btnText}
          </Button>
        </div>
      </>
    </components.MenuList>
  );
};

const defaultOptionsTemplates = {
  leftRightText: LeftRightText,
  topBottomText: TopBottomTemplate,
};

const UniversalDropdown = ({
  onChange: pOnChange,
  components: pComponents,
  value,
  options,
  optionsConfig,
  placeholder,
  searchPlaceholder,
  selectedValue: pSelectedValue,
  getCheckboxValueDom,
  isMulti,
  isEditable,
  defaultPillPlaceholderTag,
  isShowPillsAsValues,
  isClearable,
  fetchAsyncOptions,
  showMoreAndShowLess,
  isSearchable: pIsSearchable,
  minSearchOptionCount,
  isShowSelectAll,
  selectAllOption,
  showTotalSelected,
  entityName,
  customLabel,
  formatOptionLabel: pFormatOptionLabel,
  showShadow,
  hideMenuShadow,
  showfullBorder,
  showAsPrimaryButton,
  showAsSecondaryButton,
  lineIndicator,
  dropdownAlignRight,
  customSearch,
  isDisabled,
  enableborderbottom,
  isFetchError,
  onReload,
  errorDetails,
  containerClass,
  enableOptionGroup,
  enableVirtualScroll,
  initialOptions,
  optionTemplate,
  size,
  lastOptionAsButtonConfig,
  customOptionTemplates,
  onBlur,
  disableSelectedOption,
  customMenuPosition,
  showCommaSepratedValue,
  showTooltip,
  parentTooltip,
  menuPosition,
  flipMenu,
  hideHoverEffects,
  isLoading,
  isOuterLoading,
  loadingMessage,
  isOpen: pIsOpen,
  setIsOpen: pSetIsOpen,
  flipVirtualMenu,
  showGuideText,
  guideText,
  pillVariant,
  useOptionConfigForPill,
  tooltipClass,
  dropdownPillWrapperClass,
  dropdownPillClass,
  optionsClassName,
  onClickPillValue,
  selectedOptionsLabelProps,
  doNotShowPillsAsDisabled,
  isShowCaret,
  isShowPillsInDropdown,
  enableShadowScroll,
  emptyBoxMessage,
  checkBoxLabelProps,
  isTranslateOptions: pIsTranslateOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const pillListRef = useRef();
  const firstTime = useRef(true);
  const inputValue = useRef("");

  const [isOpen, setIsOpen] = useControlState(pIsOpen, pSetIsOpen, false);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [showFullBody, setShowFullBody] = useState(false);
  const [optionToShow, setOptionsToShow] = useState([]);
  const [, setRenderCount] = useState(0);

  const isAsync = typeof fetchAsyncOptions === "function";

  const isTranslateOptions = useMemo(() => {
    if (isAsync) {
      return false;
    } else {
      return pIsTranslateOptions;
    }
  }, [pIsTranslateOptions, isAsync]);

  const isSearchable = useMemo(() => {
    if (!pIsSearchable) {
      return false;
    }

    if (isAsync) {
      return true;
    }

    if (enableOptionGroup) {
      let count = 0;
      for (let i = 0; i < options.length; i++) {
        count += options[i]?.options?.length || 0;

        if (count > minSearchOptionCount) {
          return true;
        }
      }
      return false;
    }

    return options?.length > minSearchOptionCount;
  }, [pIsSearchable, options, enableOptionGroup, minSearchOptionCount]);

  const dropdownRef = useOnClickOutside(() => {
    setIsOpen(false);
    if (onBlur) {
      onBlur();
    }
  });

  useEffect(() => {
    if (pillListRef?.current) {
      if (pillListRef?.current?.scrollHeight > pillListRef?.current?.clientHeight) {
        setShowMore(true);
        setShowLess(false);
      } else {
        setShowMore(false);
        setShowLess(false);
      }
    }
  }, []);

  const { isSelectAllSelected, isPartialSelected } = useMemo(() => {
    // optionToShow only contains visible options in dropdown
    const withOutSelectAllOptions = (optionToShow || []).filter(
      (o) => o.value !== selectAllOption?.value && !o?.disabled,
    );
    const isEverySelected =
      isShowSelectAll &&
      value?.length &&
      withOutSelectAllOptions?.every((o) => value?.includes(o?.value));
    const isSomeSelected =
      value?.length && withOutSelectAllOptions?.some((o) => value?.includes(o?.value));
    return {
      isSelectAllSelected: isEverySelected,
      isPartialSelected: isSomeSelected,
    };
  }, [value, optionToShow]);

  const selectedValues = useMemo(() => {
    if (isSelectAllSelected) {
      return [selectAllOption?.value, ...value];
    } else {
      return value;
    }
  }, [value, isSelectAllSelected]);

  const isOptionSelected = (option) => {
    if (option?.value === selectAllOption?.value) {
      return isSelectAllSelected;
    } else {
      return value?.some((val) => val === option.value);
    }
  };

  const onClickShowMore = () => {
    setShowFullBody(true);
    setShowMore(false);
    setShowLess(true);
  };
  const onClickShowLess = () => {
    setShowFullBody(false);
    setShowMore(true);
    setShowLess(false);
  };

  useEffect(() => {
    if (!isLoading && pillListRef?.current) {
      if (pillListRef?.current?.scrollHeight > pillListRef?.current?.clientHeight) {
        setShowMore(true);
        setShowLess(false);
      } else {
        setShowMore(false);
        setShowLess(false);
      }
    }
  }, [value, isLoading]);

  const getOptionDOM = (option, { context }) => {
    const isSelected = isOptionSelected(option);
    const isIndeterminateState =
      isShowSelectAll &&
      option?.value === selectAllOption?.value &&
      isPartialSelected &&
      !isSelectAllSelected;
    if (context === "menu") {
      return (
        <div className="option-with-checkbox">
          <Checkbox
            id={option?.value}
            label={option?.label}
            className={`option-checkbox mb-0 ${option?.className}`}
            indeterminate={isIndeterminateState}
            checked={isSelected}
            onChange={() => {
              // Ignoring this event as it is handled by the react-select item click
            }}
            disableLabelClick
            disabled={option?.disabled}
            textProps={checkBoxLabelProps}
          />
          {typeof getCheckboxValueDom === "function" && getCheckboxValueDom(option)}
        </div>
      );
    }
  };

  const onClickRemove = (op) => {
    const finalValue = value.filter((val) => val !== op?.value);
    pOnChange(
      finalValue,
      {},
      options.filter((o) => finalValue.includes(o?.value)),
    );
  };

  const renderPills = ({ className = "" }) => {
    const availableOptions = useOptionConfigForPill ? optionsConfig : options;
    const pillLabelToShow = (value || [])?.map((val) => {
      for (let i = 0; i < availableOptions?.length; i++) {
        if (_isEqual(availableOptions[i]?.value, val)) {
          return availableOptions[i];
        }
      }
    });
    return (
      <Pill.List
        className={`dropdown-async-pill-wrapper ${
          showFullBody ? "full" : "half"
        } ${dropdownPillWrapperClass} ${className}`}
        listRef={pillListRef}
      >
        {(pillLabelToShow || [])?.map((op) => {
          return (
            <Pill
              key={op?.value}
              variant={pillVariant}
              position="right"
              // disabled={op?.disabled || isDisabled}
              disabled={
                doNotShowPillsAsDisabled !== undefined ? !doNotShowPillsAsDisabled : isDisabled
              }
              hidePillCloseClass={!isEditable || isDisabled || op?.disabled}
              onClick={() => {
                if (onClickPillValue) {
                  onClickPillValue({ option: op });
                }
              }}
              onClickRemove={(e) => {
                e.stopPropagation();
                if (isEditable) {
                  onClickRemove(op);
                }
              }}
              className={dropdownPillClass}
            >
              <span>{op?.label}</span>
            </Pill>
          );
        })}
        {showMoreAndShowLess && (
          <>
            {!!showMore && (
              <Button link className="see-more-text" onClick={onClickShowMore}>
                <Trans>Show More</Trans>
                <span className="icon icon-angle-down ml-1" />
              </Button>
            )}
            {!!showLess && (
              <Button link className="show-less-text" onClick={onClickShowLess}>
                <Trans>Show Less</Trans>
                <span className="icon icon-angle-up ml-1" />
              </Button>
            )}
          </>
        )}
      </Pill.List>
    );
  };

  const renderdefaultPillPlaceholderTag = () => {
    if (defaultPillPlaceholderTag) {
      return (
        <Pill.List className="dropdown-async-pill-wrapper">
          <Pill variant="grey" position="right" hidePillCloseClass>
            <span>
              {typeof defaultPillPlaceholderTag === "string"
                ? t(defaultPillPlaceholderTag)
                : defaultPillPlaceholderTag}
            </span>
          </Pill>
        </Pill.List>
      );
    }

    return null;
  };

  const renderPillsAsValues = useCallback(
    ({ enablePillShadowScroll = false, className }) => {
      if (isShowPillsAsValues && isMulti) {
        if (_isEmpty(value)) {
          return renderdefaultPillPlaceholderTag();
        }
        if (enablePillShadowScroll) {
          return (
            <ShadowScroll containerClassName="shadow-scroll-pill-wrapper nowrap-text">
              {renderPills({
                className: "flex-nowrap overflow-x-visible width-fit no-margin-on-top",
              })}
            </ShadowScroll>
          );
        }
        return renderPills({ className });
      }
    },
    [value, optionsConfig, options, isShowPillsAsValues, isMulti, showMore, showLess],
  );

  const renderGuideText = useMemo(() => {
    return <>{guideText && <div className="pill-guide-text guide-text hide-if-empty">{t(guideText)}</div>}</>;
  }, [guideText]);

  const overridesComponents = useMemo(() => {
    let comp = { ...pComponents, IndicatorSeparator: null };

    if (optionTemplate) {
      comp.Option = (optionProps) => defaultOptionsTemplates[optionTemplate]?.({ ...optionProps, optionsClassName });

      if (customOptionTemplates) {
        comp.Option = (optionProps) => customOptionTemplates[optionTemplate]?.({ ...optionProps, optionsClassName });
      }
    }

    if (enableVirtualScroll) {
      comp = {
        ...comp,
        MenuList: WindowedMenuList,
      };
    }

    if (lastOptionAsButtonConfig) {
      comp = {
        ...comp,
        MenuList: (restProps) => MenuListWithButton({ ...restProps, ...lastOptionAsButtonConfig }),
      };
    }

    if (!isSearchable) {
      comp = {
        ...comp,
        Control: () => {
          return null;
        },
      };
    } else {
      comp = {
        ...comp,
        DropdownIndicator,
      };
    }

    return comp;
  }, [pComponents, optionTemplate, isSearchable, enableVirtualScroll, lastOptionAsButtonConfig]);

  const selectedOptionsLabel = useMemo(() => {
    if (isMulti && showTotalSelected && value?.length) {
      if (value?.length < 2) {
        const opt = defaultGetValue(options, value?.[0], enableOptionGroup);
        return opt?.label;
      }
      return t("{{length}} {{name}} selected", {
        length: value?.length,
        name: t(entityName),
      });
    } else if (isMulti && showCommaSepratedValue && value?.length) {
      const opt = defaultGetMultipleValue(options, value, enableOptionGroup);
      return opt?.map((o) => o.label)?.join(", ");
    } else if (customLabel) {
      if (typeof customLabel === "function") {
        const opt = defaultGetValue(options, value, enableOptionGroup);
        return customLabel(opt?.label, opt);
      }
      return customLabel;
    } else if (value || typeof value === "boolean") {
      const opt = defaultGetValue(options, value, enableOptionGroup);
      return opt?.label;
    }
  });

  const recursiveSearchOption = (recursiveOptions) => {
    return _compact(
      recursiveOptions.map((op) => {
        if (op.options?.length > 0) {
          return {
            ...op,
            options: _compact(recursiveSearchOption(op?.options)),
          };
        } else if (op?.label?.toLowerCase()?.includes(inputValue?.current?.toLowerCase())) {
          return op;
        }
      }),
    );
  };

  const filterOptions = (val) => {
    if (typeof customSearch === "function") {
      return customSearch(val);
    } else if (!val) {
      if (firstTime.current && initialOptions?.length) {
        return initialOptions;
      }
      return [...(options || [])];
    } else {
      return recursiveSearchOption(options || []);
    }
  };

  const updateOptions = (callback = () => {}) => {
    const filteredOptions = filterOptions(inputValue?.current);
    if (isShowSelectAll && !_isEmpty(filteredOptions)) {
      const optionsToUse = [selectAllOption, ...filteredOptions];
      setOptionsToShow(optionsToUse);
      callback(optionsToUse);
    } else {
      callback(filteredOptions);
      setOptionsToShow(filteredOptions);
    }
  };

  useEffect(() => {
    updateOptions();
  }, [options]);

  useEffect(() => {
    firstTime.current = false;
  }, []);

  const onToggle = () => {
    setIsOpen(!isOpen);
    // inputValue.current = "";
  };

  const onChange = (newValue, actionMeta, originalValue) => {
    const { action, option, removedValue } = actionMeta || {};

    if (option?.disabled) {
      return;
    }

    if (action === "select-option" && option?.value === selectAllOption?.value) {
      const selectValues = [...(value || [])];
      optionToShow?.forEach((filterValue) => {
        if (filterValue?.value !== selectAllOption?.value && !filterValue?.disabled) {
          selectValues.push(filterValue?.value);
        }
      });
      pOnChange(_uniq(selectValues), actionMeta, originalValue);
    } else if (
      (action === "deselect-option" && option?.value === selectAllOption?.value) ||
      (action === "remove-value" && removedValue?.value === selectAllOption?.value)
    ) {
      const allOptionsValues = (optionToShow || [])
        .filter((o) => !o?.disabled)
        .map((k) => k?.value);
      pOnChange(
        (value || []).filter((filterValue) => !allOptionsValues.includes(filterValue)),
        actionMeta,
        originalValue,
      );
    } else if (action === "deselect-option") {
      pOnChange(
        value.filter((filterValue) => filterValue !== option?.value),
        actionMeta,
        originalValue,
      );
    } else if (!_isEmpty(option)) {
      pOnChange(_uniq([...newValue, ...(value || [])]) || [], actionMeta, originalValue);
    } else {
      pOnChange(newValue, actionMeta, originalValue);
    }
    if (!isMulti) {
      setIsOpen(false);
    }
  };

  const loadOptions = (search, callback) => {
    if (isAsync) {
      fetchAsyncOptions(search, (op) => {
        if (isShowSelectAll) {
          const finalOptions = [...(op.length ? [selectAllOption] : []), ...op];
          callback(finalOptions);
          setOptionsToShow(finalOptions);
        } else {
          callback(op);
          setOptionsToShow(op);
        }
      });
    } else if (options) {
      updateOptions(callback);
    }
  };

  const loadOptionToUse = isAsync ? _debounce(loadOptions, 500) : loadOptions;

  const fetchLoadOptions = useCallback(
    (search, callback) => {
      const searchText = inputValue.current;
      loadOptionToUse(searchText, callback);
    },
    [options],
  );

  return (
    <div
      className={`universal-dropdown-container ${containerClass} ${
        isSearchable ? "search-enabled" : "search-disabled"
      }`}
    >
      <Dropdown
        ref={dropdownRef}
        className={classnames(`universal-dd-wrapper ${size}`, {
          "box-shadow": showShadow,
          borderbottom: enableborderbottom && !showfullBorder,
          "full-border": showfullBorder,
          "show-as-primary-button": showAsPrimaryButton,
          "show-as-secondary-button": showAsSecondaryButton,
          "menu-position-fixed": customMenuPosition === "fixed",
          "no-hover-selected": hideHoverEffects,
          "show-pills-in-dropdown": isShowPillsInDropdown,
        })}
        onToggle={onToggle}
        show={isOpen}
        menuPosition={menuPosition}
      >
        {isLoading && isOuterLoading ? (
          <div className="animated-bg universal-dropdown-loader" />
        ) : (
          <WithTooltip title={parentTooltip}>
            <Dropdown.Toggle disabled={isDisabled}>
              <div className="selected-text-wrapper">
                <WithTooltip
                  title={showTooltip && selectedOptionsLabel}
                  tooltipClass={tooltipClass}
                >
                  <div
                    className={`selected-text d-align-center ellipsis-text ${
                      isFetchError ? "error-text" : ""
                    }`}
                  >
                    {selectedOptionsLabel ? (
                      <Text
                        className="width-flex-1"
                        innerTextClassName="ellipsis-text"
                        showEllipsis
                        {...selectedOptionsLabelProps}
                      >
                        {selectedOptionsLabel}
                      </Text>
                    ) : (
                      <Trans>
                        <span className="placeholder">{t(placeholder)}</span>
                      </Trans>
                    )}
                    {isFetchError && <span className="icon icon-warning-filled ml-2" />}
                  </div>
                </WithTooltip>
                {isShowCaret && <span
                  className={`icon ${
                    lineIndicator ? "icon-angle-down" : "icon-caret-down"
                  } dropdown-indicator`}
                />}
              </div>
            </Dropdown.Toggle>
          </WithTooltip>
        )}
        <Dropdown.Menu
          popperConfig={{ strategy: customMenuPosition }}
          alignRight={dropdownAlignRight}
          flip={(flipMenu && !enableVirtualScroll) || flipVirtualMenu}
          className="dropdown-menu"
        >
          {isOpen ? (
            <>
              {isShowPillsInDropdown ? (
                _isEmpty(value) ? (
                  <div className="empty-box-pill-wrapper p10 border-radius-top-8 d-center">
                    <Text type="tertiary" size="small" weight="regular">{emptyBoxMessage}</Text>
                  </div>
                ) : (
                  renderPillsAsValues({
                    className: "pills-outside-wrapper p10 border-radius-top-8 no-margin-on-top",
                  })
                )
              ) : null}
              {isFetchError ? (
                <div className="retry-wrapper text-center d-flex-column-center">
                  <Text className="mb-3" type="primary" size="medium">
                    {errorDetails?.description}
                  </Text>
                  {errorDetails?.ctaText ? (
                    <Button className="ml-0" link icon={errorDetails?.ctaIcon} onClick={onReload}>
                      {errorDetails?.ctaText}
                    </Button>
                  ) : null}
                </div>
              ) : (
                <BaseDropdown
                  cacheOptions={false}
                  defaultOptions={isAsync ? true : optionToShow}
                  {...props}
                  containerClassName={`${props.containerClassName || ""} ${
                    hideMenuShadow ? "no-menu-shadow" : ""
                  }`}
                  enableOptionGroup={enableOptionGroup}
                  menuPlacement="bottom"
                  SelectComponent={AsyncSelect}
                  autoFocus
                  backspaceRemovesValue={false}
                  components={overridesComponents}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={isClearable}
                  onKeyDown={(e) => {
                    if (typeof props?.onKeyDown === "function") {
                      props.onKeyDown(e);
                    } else if ((e.keyCode === 32 || e?.keyCode === 13) && !e?.target?.value) {
                      e.preventDefault();
                    }
                  }}
                  menuIsOpen
                  onChange={onChange}
                  tabSelectsValue={false}
                  value={selectedValues}
                  placeholder={t(searchPlaceholder)}
                  formatOptionLabel={pFormatOptionLabel || (isMulti && getOptionDOM)}
                  loadOptions={fetchLoadOptions}
                  isMulti={isMulti}
                  options={optionToShow}
                  isLoading={isLoading && !isOuterLoading}
                  loadingMessage={() => t(loadingMessage)}
                  inputValue={inputValue?.current}
                  isTranslateOptions={isTranslateOptions}
                  onInputChange={(val, action) => {
                    if (action.action === "input-change") {
                      inputValue.current = val;
                      if (isAsync) {
                        setRenderCount((old) => old + 1);
                      }
                    } // this is for search value
                    return isAsync ? inputValue.current : "dummyVal"; // using this because search is not working properly
                  }}
                />
              )}
            </>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
      {showGuideText ? renderGuideText : null}
      {isShowPillsAsValues && renderPillsAsValues({ enablePillShadowScroll: enableShadowScroll })}
    </div>
  );
};

UniversalDropdown.defaultProps = {
  searchPlaceholder: "Search",
  isMulti: false,
  isShowPillsAsValues: false,
  isClearable: false,
  showMoreAndShowLess: false,
  isSearchable: true,
  isEditable: true,
  isShowSelectAll: false,
  isShowCaret: true,
  isShowPillsInDropdown: false,
  selectAllOption: {
    value: "<SELECT_ALL>",
    label: "Select All",
    className: "select-all-option",
  },
  showTotalSelected: false,
  entityName: "options",
  showShadow: false,
  hideMenuShadow: false,
  showfullBorder: false,
  showAsButton: false,
  lineIndicator: false,
  isFetchError: false,
  errorDetails: {
    description: "Results failed to fetch, Please reload",
    ctaText: "Reload",
    ctaIcon: "icon-undo-2",
  },
  enableborderbottom: true,
  enableVirtualScroll: true,
  minSearchOptionCount: 5,
  cacheOptions: false,
  customMenuPosition: "absolute",
  showTooltip: false,
  flipMenu: false, // enable this only when virtual scroll is off
  showGuideText: false,
  pillVariant: "grey",
  useOptionConfigForPill: false,
  flipVirtualMenu: false,
  dropdownPillWrapperClass: "",
  dropdownPillClass: "",
  loadingMessage: "Loading...",
};

export default UniversalDropdown;