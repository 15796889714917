import * as SentryReact from "@sentry/react";
import { replayIntegration } from "@sentry/browser";
import { isChunkError } from "./util";

export const sentryIgnoreErrors = [
  "Non-Error promise rejection captured with keys",
  "Non-Error promise rejection captured with value",
  "Object captured as promise rejection with keys",
  "NetworkError: Load failed",
  "NetworkError: A network error occurred",
  "NetworkError: The internet connection appears to be offline.",
  "NotSupportedError: Failed to register a ServiceWorker for scope",
  "NetworkError: WorkerGlobalScope.importScripts: Failed to load worker script",
  "Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'",
  "Unable to load external reCAPTCHA dependencies!",
  "TypeError: Failed to register a ServiceWorker for scope",
  "AbortError: Failed to register a ServiceWorker for scope",
  "null is not an object (evaluating 'v.style')",
  "serviceWorker",
  "Error: Network Error",
  "Uncaught SyntaxError: Unexpected token '<'",
  "SyntaxError: Unexpected token '<'",
  "SyntaxError: expected expression, got '<'",
  "window.webkit.messageHandlers.srcImgs.postMessage",
  "Cannot read properties of undefined (reading 'isSVG')",
  "().getRestrictions is not a function",
  "(gtag/js)",
  "AbortError: signal is aborted without reason",
];

export const sentryBaseConfig = {
  maxBreadcrumbs: 20,
  ignoreErrors: sentryIgnoreErrors,
  normalizeDepth: 10,
  denyUrls: [/recaptcha\//i, /goo.gl\//i, /cdn.pendo.io\//i, /gtag\//i, /gtm\//i],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  integrations: [],
  // integrations: [new SentryReact.Replay({
  //   maskAllText: false,
  //   blockAllMedia: false,
  //   maskAllInputs: false,
  //   maskInputOptions: { password: true },
  // })],
};

export const sentryErrorBoundryBeforeCapture = (scope, error) => {
  if (isChunkError(error)) {
    scope.setLevel("info");
    scope.setTag("chunkLoadError", true);
  }
  scope.setTag("isDowntime", true);
};

export const Sentry = SentryReact;

export const startSentryReplay = () => {
  try {
    const client = Sentry?.getClient?.();
    if (client?.addIntegration) {
      client.addIntegration(
        replayIntegration({
          minReplayDuration: 5000,
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          maskInputOptions: { password: true },
          networkDetailAllowUrls: [
            window.location.origin,
            "osapi.onlinesales.ai",
            "os-staging.onlinesales.ai",
          ],
        }),
      );
    }
  } catch (err) {}
};
