import React, { useEffect } from "react";

const TranslationErrorDetector = () => {
  const audioContextRef = React.useRef(null);
  const audioInitialized = React.useRef(false);

  const initAudio = () => {
    if (!audioInitialized.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      audioInitialized.current = true;
    }
  };

  // Audio Feedback when error occurs
  const playBeep = () => {
    try {
      if (!audioInitialized.current) {
        initAudio();
      }

      if (audioContextRef.current) {
        const oscillator = audioContextRef.current.createOscillator();
        const gainNode = audioContextRef.current.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);

        oscillator.type = "sine";
        oscillator.frequency.setValueAtTime(800, audioContextRef.current.currentTime);
        gainNode.gain.setValueAtTime(0.1, audioContextRef.current.currentTime);

        oscillator.start();
        oscillator.stop(audioContextRef.current.currentTime + 0.2);
      }
    } catch (error) {
      console.warn("Error playing beep:", error);
    }
  };

  useEffect(() => {
    if (NODE_ENV === "development") {
      if (typeof Node === "function" && Node.prototype) {
        function highlightElement(element) {
          if (element && element.style) {
            element.style.border = "2px solid red";
            element.style.backgroundColor = "rgba(255, 0, 0, 0.1)";
          }
        }

        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
          if (child.parentNode !== this) {
            console.log("Cannot remove a child from a different parent", child, this);
            highlightElement(child);
            highlightElement(this);
            playBeep();
            return child;
          }
          return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
          if (referenceNode && referenceNode.parentNode !== this) {
            highlightElement(newNode);
            highlightElement(this);
            if (console) {
              console.error(
                "Cannot insert before a reference node from a different parent",
                referenceNode,
                this,
              );
            }
            playBeep();
            return newNode;
          }
          return originalInsertBefore.apply(this, arguments);
        };
      }
    }

    // Cleanup AudioContext on unmount
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  return null;
};

export default TranslationErrorDetector;
