import React from "react";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";

import classnames from "classnames";

import { Textarea as TextareaInput } from "@onlinesales-ai/input-v2";
import { DynamicField } from "@onlinesales-ai/utils-components-v2";
import { validateValue } from "@onlinesales-ai/util-methods-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import FormWrapper from "../../FormWrapper";

import "./index.less";

const regex = {
  DYNAMIC_VERIABLE: /\${(.+)}/i,
};

class Textarea extends React.Component {
  constructor(props) {
    super(props);

    const val = this.getValue();

    this.state = {
      errorWarning: this.getFieldErrorWarning(val),
      customErrorClass: null,
    };

    this.validate(val);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { validations } = this.props;
    const newValue = _get(nextProps.formValues, nextProps.dataKey);
    const newErrorValue = _get(nextProps.formErrors, nextProps.dataKey);
    const { dataKey, formValues } = this.props;

    if (
      newValue !== this.getValue() ||
      (!newValue && newErrorValue !== this.getError()) ||
      !_isEqual(validations, nextProps.validations)
    ) {
      this.validate(newValue, nextProps.validations);
      this.computeWarning(newValue);
    }
  }

  getValue = () => {
    const { dataKey, formValues } = this.props;
    return _get(formValues, dataKey);
  };

  getError = () => {
    const { dataKey, formErrors } = this.props;
    return _get(formErrors, dataKey);
  };

  getFieldErrorWarning = (value) => {
    const { errorWarning: pErrorWarning, enableDynamicField, dynamicFieldWraning } = this.props;

    if (pErrorWarning) {
      return null;
    }

    if (enableDynamicField && regex.DYNAMIC_VERIABLE.test(value)) {
      return dynamicFieldWraning;
    }

    return null;
  };

  computeWarning = (value) => {
    const { errorWarning } = this.state;

    const warning = this.getFieldErrorWarning(value);

    if (warning) {
      this.setState({
        errorWarning: warning,
      });
    } else if (errorWarning) {
      this.setState({
        errorWarning: null,
      });
    }
  };

  validate = (value, pValidations) => {
    const { onError, dataKey, validations = [], title, formValues } = this.props;
    let errorMsg = null;
    const validationsToUse = pValidations || validations;
    const validationValue = validateValue({
      validations: validationsToUse,
      value,
      formValues,
      title,
    });
    errorMsg = validationValue?.errorMsg;
    const customErrorClass = validationValue?.errorClassName;
    this.setState({
      customErrorClass,
    });
    onError({ [dataKey]: errorMsg });
  };

  onChangeValue = (event) => {
    const { onChange, dataKey, trimOnChange, doNotSendEmptyString, emptyStringValueToSet } =
      this.props;
    let valueToAdd = event.target.value;

    if (trimOnChange && typeof valueToAdd === "string") {
      valueToAdd = valueToAdd.trim();
    }

    if (!valueToAdd && doNotSendEmptyString) {
      valueToAdd = emptyStringValueToSet;
    }

    onChange({
      [dataKey]: valueToAdd,
    });
  };

  onBlur = (e) => {
    const { trimOnBlur } = this.props;
    const { value } = e.target;
    if (trimOnBlur && typeof value === "string") {
      this.onChangeValue({ target: { value: value.trim() } });
    }
  };

  onSelectDynamicField = (param) => {
    const { onChange, dataKey } = this.props;
    const value = this.getValue();

    onChange({
      [dataKey]: DynamicField.valueReplacer(value, param),
    });
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      warningicon,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,
      infoTooltip,
      isRequired,
      errorWarning: pErrorWarning,
      labelBottomComponent,
      errorClassName,
      textareaWrapperClassName,
      formGroupInnerClassName,
      labelClassName,
      className,

      // textarea props
      dataKey,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      placeholder,

      // dynamic field props
      enableDynamicField,
      dynamicOptions,
      dynamicTemplate,
      toolTip,
      placement,
      defaultValuePlaceholder,

      maxlength,
      suffix,
      doNotShowCharLimit,
      labelDefinationKey,
      icon,
      isDisabled,
      dynamicFieldClassName,
      nonEditModeClassName,
    } = this.props;
    const { errorWarning, customErrorClass } = this.state;

    const value = this.getValue();
    let characterLimit = null;
    if (!doNotShowCharLimit && maxlength && !suffix) {
      characterLimit = `${value ? value.length : 0}/${maxlength}`;
    }

    const renderTextArea = (inputRef) => {
      return (
        <div className="textarea-form-wrapper">
          <TextareaInput
            ref={inputRef}
            characterLimit={characterLimit}
            value={value || ""}
            onChange={this.onChangeValue}
            disabled={disabled || isDisabled}
            placeholder={placeholder}
            maxlength={maxlength}
            textareaWrapperClassName={textareaWrapperClassName}
            className={className}
            onBlur={this.onBlur}
          />
        </div>
      );
    };

    const renderTextAreaDom = ({ inputRef }) => {
      return (
        <>
          {toolTip ? (
            <WithTooltip title={toolTip} placement={placement}>
              {renderTextArea(inputRef)}
            </WithTooltip>
          ) : (
            renderTextArea(inputRef)
          )}
        </>
      );
    };

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={`textarea-form-group-wrapper ${formGroupClassName || ""}`}
        formGroupInnerClassName={formGroupInnerClassName}
        warningicon={warningicon}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElement}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        infoTooltip={infoTooltip}
        isRequired={isRequired}
        errorWarning={pErrorWarning || errorWarning}
        labelBottomComponent={labelBottomComponent}
        labelDefinationKey={labelDefinationKey}
        errorClassName={errorClassName}
        icon={icon}
        customErrorClass={customErrorClass}
        labelClassName={labelClassName}
      >
        {isEditable ? (
          <>
            {enableDynamicField ? (
              <DynamicField
                valueTemplate={dynamicTemplate}
                options={dynamicOptions}
                onSelect={this.onSelectDynamicField}
                containerClass={dynamicFieldClassName}
              >
                {renderTextAreaDom}
              </DynamicField>
            ) : (
              renderTextAreaDom({})
            )}
          </>
        ) : (
          <div className={classnames("non-edit-mode-text", nonEditModeClassName)}>{value || defaultValuePlaceholder}</div>
        )}
      </FormWrapper>
    );
  }
}

Textarea.defaultProps = {
  dataKey: null,
  title: "",
  labelColumns: 3,
  formGroupClassName: "form-component-textarea",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default Textarea;
