/* eslint-disable no-else-return */
import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import { Trans } from "react-i18next";

import AsyncImage from "@onlinesales-ai/async-image-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { loaderWhite, OverlayLoader } from "@onlinesales-ai/loader-v2";
import { ModalWindow } from "@onlinesales-ai/modal-window-v2";
import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import MediaUpload from "./index";

// import "./index.less";
import "./mediaUploadWithPopup.less";

const CLOUD_IMAGE =
  "https://osads.gumlet.io/image/upload/v1612964338/product/file-upload.svg";

const FILE_UPLOAD_PROGRESS_WEIGHTAGE = 90;

class MediaUploadWithProgress extends Component {
  constructor(props) {
    super(props);

    this.statusEnum = {
      NOT_STARTED: {
        key: "NOT_STARTED",
        display: "Not Started",
        variant: "warning",
        containerClass: "upload-not-started",
      },
      IN_PROGRESS: {
        key: "IN_PROGRESS",
        display: "File uploading",
        variant: "info",
        containerClass: "upload-in-progress",
        iconImage: CLOUD_IMAGE,
      },
      COMPLETED: {
        key: "COMPLETED",
        display: "File upload completed",
        containerClass: "upload-success",
        variant: "success",
        iconClass: "icon-check1",
      },
      FAILED: {
        key: "FAILED",
        display: "File upload failed",
        containerClass: "upload-failed",
        variant: "danger",
        iconClass: "icon-close",
      },
      WARNING: {
        key: "WARNING",
        display: "File upload completed with errors",
        variant: "warning",
        iconClass: "icon-bid_alert_inherited",
        containerClass: "upload-completed-with-errors",
      },
      ...(props?.uploadStatusEnum || {}),
    };

    this.state = {
      fileUploadStatus: this.getInitialUploadStatus(),
      showSuccessModal: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.accept !== nextProps.accept) {
      this.setState({
        fileUploadStatus: this.getInitialUploadStatus(),
      });
    }
  }

  getInitialUploadStatus = () => ({
    completed: 0,
    eta: 0,
    status: this.statusEnum.NOT_STARTED?.key,
    fileName: null,
    fileSize: 0,
  });

  getCloudinaryTags = () => {
    const { userInfo, creativeTag, uploadTags = [] } = this.props;
    return [`uploaded_by:${userInfo?.email || ""}`, creativeTag, ...uploadTags];
  };

  onUploadError = (errorMsg) => {
    const { fileUploadStatus = {} } = this.state;
    const { showToastMessage = () => {} } = this.props;
    this.setState({
      fileUploadStatus: {
        ...fileUploadStatus,
        completed: 100,
        eta: 0,
        status: this.statusEnum.FAILED?.key,
      },
    });
    showToastMessage({
      type: "ERROR",
      messageToDisplay: errorMsg,
      actionButtonLabel: null,
      toastDuration: 5000,
      className: "pendo-track-media-file-upload-error",
    });
  };

  onStartUpload = (file) => {
    const { fileUploadStatus = {} } = this.state;

    this.setState({
      fileUploadStatus: {
        ...fileUploadStatus,
        status: this.statusEnum.IN_PROGRESS?.key,
        fileName: file.name,
        fileSize: file.size,
      },
    });
  };

  uploadProgressCallback = (stat) => {
    const { fileUploadStatus = {} } = this.state;
    const { extraETAMargin } = this.props;

    this.setState({
      fileUploadStatus: {
        ...fileUploadStatus,
        completed: (stat.completed * FILE_UPLOAD_PROGRESS_WEIGHTAGE) / 100,
        // Adding Service callback time
        eta: stat.completed !== 100 ? stat.eta + parseInt(extraETAMargin) : 0,
        status: this.statusEnum.IN_PROGRESS?.key,
      },
    });
  };

  onMediaUploadComplete = async (...params) => {
    const { fileUploadStatus = {} } = this.state;
    const { onUpload, showSuccessInModal } = this.props;
    try {
      await onUpload(...params);
      if (showSuccessInModal) {
        this.setState({
          showSuccessModal: true,
          fileUploadStatus: this.getInitialUploadStatus(),
        });
      } else {
        this.setState({
          fileUploadStatus: {
            ...fileUploadStatus,
            completed: 100,
            eta: 0,
            status: this.statusEnum.COMPLETED?.key,
          },
        });
      }
    } catch (error) {
      this.setState({
        fileUploadStatus: {
          ...fileUploadStatus,
          completed: 100,
          eta: 0,
          status: this.statusEnum.FAILED?.key,
        },
      });
    }
  };

  resetFileUploadStatus = () => {
    this.setState({
      fileUploadStatus: this.getInitialUploadStatus(),
    });
  };

  getOverlayDomNode = () => {
    const { overlayDomNode, overlayDomText, showMediaLibrary } = this.props;
    if (overlayDomNode) {
      return overlayDomNode;
    } else {
      return (
        <div className="media-progress-overlay-dom">
          <AsyncImage imgClassName="overlay-image" imgSrc={CLOUD_IMAGE} />
          <div className="overlay-dom-text">
            <Trans>{overlayDomText}</Trans>
            <br />
            {showMediaLibrary && <span className="separator"><Trans>(OR)</Trans></span>}
          </div>
        </div>
      );
    }
  };

  successDom = () => {
    const { fileUploadSuccessMsg } = this.props;

    return (
      <div className="media-upload-successful">
        <AsyncImage imgSrc="https://osads.gumlet.io/image/upload/v1614264577/BulkSku/winning-bulk-sku.svg" />
        <div className="upload-success-message">
          {typeof fileUploadSuccessMsg === "string" ? (
            <Trans>{fileUploadSuccessMsg}</Trans>
          ) : (
            fileUploadSuccessMsg
          )}
        </div>
      </div>
    );
  };

  renderSuccessModal = () => {
    const { showSuccessModal } = this.state;
    return (
      <ModalWindow
        isShow={showSuccessModal}
        onModalDestroy={() => this.setState({ showSuccessModal: false })}
        headerTitle="Uploaded Successfully"
        containerClass="media-success-modal-container"
        modalProps={{
          backdrop: "static",
        }}
      >
        {this.successDom()}
      </ModalWindow>
    );
  };

  getProgressScreens = () => {
    const { fileUploadStatus = {} } = this.state;
    const { showSuccessModal } = this.props;
    const currentStatus = this.statusEnum[fileUploadStatus.status] || {};

    if (!showSuccessModal && currentStatus.key === this.statusEnum.COMPLETED?.key) {
      return this.successDom();
    }

    return (
      <div className={`media-progress-block ${currentStatus.containerClass}`}>
        <div className="media-progress-info">
          {currentStatus.iconImage && <AsyncImage imgSrc={currentStatus.iconImage} />}
          {currentStatus.iconClass && <span className={`icon ${currentStatus.iconClass}`} />}
          <div className="media-progress-display-text">
            <Trans>{currentStatus.display}</Trans>
          </div>
          {currentStatus.key === this.statusEnum.IN_PROGRESS?.key && loaderWhite()}
        </div>
        <div className="media-progress-bar">
          <ProgressBar variant={currentStatus.variant} now={fileUploadStatus.completed} />
          {fileUploadStatus.completed < 100 && <span className="icon icon-loader" />}
        </div>
        <WithTooltip title={fileUploadStatus.fileName}>
          <div className="media-progress-file-name ellipsis-line twoline">{fileUploadStatus.fileName}</div>
        </WithTooltip>
        {currentStatus.key === this.statusEnum.FAILED?.key && (
          <div className="media-progress-failed">
            <div className="button btn-link cursor-pointer cloud-image" onClick={this.resetFileUploadStatus}>
              <AsyncImage imgSrc={CLOUD_IMAGE} />
              <Trans>Upload new file</Trans>
            </div>
            <Button className="re-upload-btn" onClick={this.resetFileUploadStatus} link icon="icon-goals-reset-bold">Re-Upload</Button>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { fileUploadStatus = {} } = this.state;
    const {
      clientId,
      userInfo,
      creativeTag,
      showToastMessage,
      onUpload,
      uploadStatusEnum,
      extraETAMargin,
      containerClass,
      sampleFileText,
      overlayDomNode,
      sampleFileUrl,
      overlayDomText,
      onClickDownloadSampleFile,
      showOverlayLoader,
      showSuccessInModal,
      shouldShowMediaUploadOnUpload,
      infoText,
      ...restProps
    } = this.props;

    return (
      <div
        className={`media-upload-with-progress-container ${containerClass} ${
          this.statusEnum[fileUploadStatus.status]?.key
        }`}
      >
        {showOverlayLoader ? <OverlayLoader /> : null}
        {(fileUploadStatus.status === this.statusEnum.NOT_STARTED?.key || shouldShowMediaUploadOnUpload) && (
          <div className="default-state">
            <MediaUpload
              {...restProps}
              containerClass=""
              onUploadError={this.onUploadError}
              overlayDomNode={this.getOverlayDomNode()}
              clientId={clientId}
              onUpload={this.onMediaUploadComplete}
              onStartUpload={this.onStartUpload}
              cloudinaryTags={this.getCloudinaryTags()}
              useXHR
              onProgress={this.uploadProgressCallback}
            />
            <div className="download-sample-file-container">
              {sampleFileUrl ? (
                <a className="sample-file-link" href={sampleFileUrl} rel="noreferrer" target="_blank">
                  <Trans>{sampleFileText}</Trans>
                </a>
              ) : null}
              {onClickDownloadSampleFile ? (
                <a
                  href="javascript:void(0)"
                  className="sample-file-link"
                  onClick={onClickDownloadSampleFile}
                  rel="noreferrer"
                >
                  <Trans>{sampleFileText}</Trans>
                </a>
              ) : null}
            </div>
              {infoText ? (
                <span className="infoText">
                  {infoText}
                </span>
              ) : null }
          </div>
        )}
        {
          !shouldShowMediaUploadOnUpload &&
          fileUploadStatus.status !== this.statusEnum.NOT_STARTED?.key &&
          this.getProgressScreens()
        }
        {showSuccessInModal ? this.renderSuccessModal() : null}
      </div>
    );
  }
}

MediaUploadWithProgress.defaultProps = {
  overlayDomNode: null,
  extraETAMargin: 5,
  overlayDomText: "Upload .tsv file with details",
  isUploadOnCloudinary: false,
  sampleFileText: "View sample file",
  sampleFileUrl: "",
  fileUploadSuccessMsg: "File uploaded successfully",
  onUpload: () => {},
  showSuccessInModal: false,
  shouldShowMediaUploadOnUpload: false,
};

export default OSHOCWithUtilities(MediaUploadWithProgress);
