import React, { useEffect, useMemo } from "react";
import _get from "lodash/get";
import _set from "lodash/set";
import _pick from "lodash/pick";
import _omit from "lodash/omit";
import _groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import classnames from "classnames";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";

import { Button } from "@onlinesales-ai/button-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

import FormWrapper from "../../FormWrapper";
import Dropdown from "../dropdown";
import DropdownSubtitle from "../dropdownSubtitle";
import UniversalDropdown from "../universalDropdown";
import { useTranslation } from "react-i18next";

const BaseAction = ({
  title,
  dataKey,
  formValues,
  actionsToShow,
  actionConfig,
  formErrors,
  menuPlacement,
  isEditable,
  minAction,
  maxAction,
  editMode,
  showErrors,
  onChange,
  onError,
  renderComponent,
  actionTitle,
  actionTitleColumn,
  customRenderer,
  showIndexInTitle,
  formGroupClassName,
  typeKey,
  enableDuplicateAction,
  labelColumnsSize,
  drodownProps,
  labelDefinationKey,
  enableOptionGroup,
  enableDescription,
  showAndLabel,
  customAddButton,
  allowSameActions,
  autoSetFirst,
  titleGuidText,
  actionDropdownProps,
  infoTooltip,
  contentTopRender,
  commonInitValueToAdd,
  enableUniversalDropdown,
  getOperatorValueOnChange,
  removeIcon,
  duplicateIcon,
  showConfirmationOnDelete,
  showConfirmationModal,
  resetConfirmationModal,
  dataKeysToCheckBeforeDelete,
  actionTitleLabelDefinationKey,
  errorClassName,
  labelClassName,
  doNotSetDefaultAction,
  commonComponentToShow,
  commonComponentConfig,
  customProcessActionsToShow,
  removeActionTooltip,
  duplicateActionTooltip,
  baseFormRef,
  contentClassName,
  ...props
}) => {
  const { t } = useTranslation();

  const value = useMemo(() => {
    return _get(formValues, dataKey) || [];
  }, [dataKey, formValues]);

  const actionOptions = useMemo(() => {
    let options = actionsToShow;

    if (!allowSameActions) {
      options = options.filter((action) => {
        return !value.find((item) => {
          const val = _get(item, typeKey);
          return val === action;
        });
      });
    }

    return options.map((action) => {
      const config = actionConfig[action];

      return {
        label: config?.label,
        value: action,
        group: config?.actionGroup,
        disabled: config?.disabled,
        description: config?.description,
      };
    });
  }, [actionsToShow, actionConfig, value, allowSameActions, typeKey]);

  useEffect(() => {
    let isNotSet = !value.length;

    if (value.length === 1) {
      isNotSet = _isEmpty(value[0]);
    }

    if (isNotSet && actionOptions[0] && autoSetFirst) {
      const { valuesToSet = {} } = actionConfig?.[actionOptions[0].value] || {};
      const val = {};
      _set(val, typeKey, actionOptions[0].value);
      onChange({
        [dataKey]: [{ ...val, ...commonInitValueToAdd, ...valuesToSet }],
      });
    }
  }, [value, autoSetFirst, actionOptions]);

  const onChangeType = (newType, index) => {
    onChange({
      [dataKey]: value.map((v, i) => {
        const typeValue = _get(v, typeKey);
        if (i === index && newType !== typeValue) {
          _set(v, typeKey, newType);
          return {
            ...(enableDuplicateAction ? _cloneDeep(v) : {}),
            ..._pick(v, [typeKey]),
            ...commonInitValueToAdd,
            ...(actionConfig[newType]?.valuesToSet || {}),
          };
        }
        return v;
      }),
    });
    onError(
      Object.keys(formErrors).reduce((memo, key) => {
        if (key.includes(`${dataKey}[${index}].`)) {
          memo[key] = null;
        }
        return memo;
      }, {}),
    );
  };

  const onErrorType = (error) => {
    onError(error);
  };

  const onClickAdd = () => {
    const valToAdd = actionOptions[0].value;
    const valObj = {};
    _set(valObj, typeKey, valToAdd);
    onChange({
      [dataKey]: [
        ...value,
        {
          ...(!doNotSetDefaultAction && valObj),
          ...commonInitValueToAdd,
          ...(doNotSetDefaultAction ? {} : actionConfig[valToAdd]?.valuesToSet || {}),
        },
      ],
    });
  };

  const performRemoveActions = (index) => {
    onChange({ [dataKey]: value.filter((item, i) => i !== index) });
    onError(
      Object.keys(formErrors).reduce((memo, key) => {
        if (
          key.includes(`${dataKey}[${index}].`) ||
          key.includes(`${dataKey}[${value.length - 1}].`)
        ) {
          memo[key] = null;
        }
        return memo;
      }, {}),
    );
  };

  const onClickRemove = (index) => {
    let showConfirmation = showConfirmationOnDelete;

    if (showConfirmation) {
      const list = dataKeysToCheckBeforeDelete
        .map((k) => _get(value?.[index], k))
        .filter((ele) => {
          if (typeof ele === "object") {
            return !_isEmpty(ele);
          }
          return Boolean(ele);
        });
      showConfirmation = !!list.length;
    }

    if (showConfirmation) {
      showConfirmationModal({
        isShow: true,
        title: t("Are you sure want to delete, you will lost your selected value ?"),
        actionBtnText: "Yes",
        rightBtnText: "No",
        actionBtnCallback: () => {
          performRemoveActions(index);
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      performRemoveActions(index);
    }
  };

  const onClickDuplicate = ({ index }) => {
    const duplicateValueFromKey = allowSameActions ? value[index] : _omit(value[index], [typeKey]);
    value.splice(index + 1, 0, duplicateValueFromKey);

    onChange({ [dataKey]: value });
  };

  const onChangeOperator = (val, { opratorComponentConfig, index, operaterKeyPath }) => {
    const indexVal = value?.[index];
    const { operatorKey } = actionConfig?.[_get(indexVal, typeKey)] || {};
    if (!_isEqual(_get(indexVal, operatorKey), _get(val, operaterKeyPath))) {
      if (opratorComponentConfig?.resetKey) {
        onChange({
          [dataKey]: value?.map?.((v, ind) => {
            if (ind === index) {
              return _omit(v, opratorComponentConfig.resetKey);
            }
            return v;
          }),
        });
      }

      const resetErrorKeys = opratorComponentConfig?.componentToShow?.map?.(
        (c) => `${dataKey}[${index}].${opratorComponentConfig.componentConfig[c].props.dataKey}`,
      );

      if (resetErrorKeys?.length) {
        onError(resetErrorKeys.reduce((memo, key) => ({ ...memo, [key]: undefined }), {}));
      }

      const valueToSet = getOperatorValueOnChange ? getOperatorValueOnChange(val, value) : val;

      onChange(valueToSet);
    }
    if (_isEqual(_get(value?.[index], operatorKey), _get(val, operaterKeyPath))) {
      return;
    }
  };

  const renderActionDropdown = ({ index, val, extraProps }) => {
    const typeDataKey = `${dataKey}[${index}].${typeKey}`;
    const typeValue = _get(val, typeKey);

    let actionDropdownOptions = [
      ...actionOptions,
    ];

    if (!allowSameActions) {
      actionDropdownOptions.unshift(
        {
          label: actionConfig[typeValue]?.label,
          value: typeValue,
          group: actionConfig[typeValue]?.actionGroup,
          description: actionConfig[typeValue]?.description,
        },
      );
    }

    if (enableOptionGroup) {
      const groupedData = _groupBy(actionDropdownOptions, "group");
      actionDropdownOptions = Object.keys(groupedData).map((key) => {
        return {
          label: key,
          options: groupedData[key],
        };
      });
    }

    if (typeof customProcessActionsToShow === "function") {
      actionDropdownOptions = customProcessActionsToShow(actionDropdownOptions, {
        index, val, extraProps
      });
    }

    const DropdownComponent = enableUniversalDropdown
      ? UniversalDropdown
      : enableDescription
      ? DropdownSubtitle
      : Dropdown;

    return (
      <DropdownComponent
        title={actionTitle ? `${t(actionTitle)} ${showIndexInTitle ? index + 1 : ""}` : ""}
        options={actionDropdownOptions}
        onChange={(typeVal) => onChangeType(typeVal[typeDataKey], index)}
        formGroupClassName="form-component-dropdown action-dropdown pendo-track-filter-action-dropdown"
        onError={onErrorType}
        formErrors={formErrors}
        menuPlacement={menuPlacement}
        formValues={formValues}
        dataKey={typeDataKey}
        isEditable={isEditable}
        editMode={editMode}
        showErrors={showErrors}
        labelColumns={actionTitle ? actionTitleColumn : 0}
        drodownProps={drodownProps}
        enableOptionGroup={enableOptionGroup}
        labelDefinationKey={actionTitleLabelDefinationKey}
        {...extraProps}
        {...actionDropdownProps}
      />
    );
  };

  const renderActionComponents = ({ val, index }) => {
    const typeValue = _get(val, typeKey);
    const { operatorKey, operatorConfig, componentToShow: componentToShowP, componentConfig: componentConfigP } =
      actionConfig?.[typeValue] || {};
    const opratorComponentConfig = operatorConfig?.[val?.[operatorKey]];

    const componentToShow = componentToShowP ?? commonComponentToShow;
    const componentConfig = componentConfigP ?? commonComponentConfig;

    return (
      <div key={typeValue} className={`sub-components-wrapper pendo-track-filter-sub-component-wrapper ${typeValue}`}>
        {componentToShow?.map?.((key) => {
          const component = componentConfig[key];
          const operaterKeyPath = `${dataKey}[${index}].${component.props.dataKey}`;
          return renderComponent({
            component,
            props,
            overrides: {
              ...component.props,
              keyPrefix: typeValue,
              dataKey: operaterKeyPath,
              additionalDataKeyPrefix: `${dataKey}[${index}].`,
              ...(component.props.dataKey === operatorKey
                ? {
                  onChange: (val) => onChangeOperator(val, { opratorComponentConfig, index, operaterKeyPath }),
                }
                : {}),
            },
          });
        })}
        {opratorComponentConfig?.componentToShow?.map?.((key) => {
          const component = opratorComponentConfig?.componentConfig?.[key];
          return renderComponent({
            component,
            props,
            overrides: {
              ...component.props,
              dataKey: `${dataKey}[${index}].${component.props.dataKey}`,
              dataKeyPrefix: `${dataKey}[${index}].`,
              keyPrefix: val?.[operatorKey],
            },
          });
        })}
      </div>
    );
  };

  const renderRemoveButton = ({ index }) => {
    const showRemove = value.length > minAction && isEditable;

    return (
      <WithTooltip title={removeActionTooltip}>
        <div className="remove-action pendo_ott_feature_remove_rule_action_button" onClick={() => (showRemove ? onClickRemove(index) : null)}>
          {showRemove ? <span className={`icon ${removeIcon || "icon-close1"}`} /> : null}
        </div>
      </WithTooltip>
    );
  };

  const renderDuplicateButton = ({ index }) => {
    const showDuplicate =
      (enableDuplicateAction && isEditable && value.length < actionsToShow.length);

    return showDuplicate ? (
      <WithTooltip title={duplicateActionTooltip}>
        <div className="duplicate-action cursor-pointer" onClick={() => onClickDuplicate({ index })}>
          <span className={`icon default-icon ${duplicateIcon || "icon-copy-2"}`} />
        </div>
      </WithTooltip>
    ) : null;
  };

  const renderAndLabel = () => {
    if (showAndLabel) {
      return <div className="and-label">and</div>;
    }

    return null;
  };

  const renderAddButton = () => {
    if (
      isEditable &&
      (maxAction ? value.length < maxAction : true) &&
      (allowSameActions ? true : value.length < actionsToShow.length)
    ) {
      if (customAddButton) {
        return customAddButton({ onClickAdd });
      }
      return (
        <Button
          className="add-button"
          type="default"
          shape="rounded"
          icon="icon-add-plus"
          iconWeight
          onClick={onClickAdd}
        />
      );
    }

    return null;
  };

  if (baseFormRef) {
    baseFormRef.current = {
      performRemoveActions,
    };
  }

  return (
    <FormWrapper
      title={title}
      infoTooltip={infoTooltip}
      labelColumns={labelColumnsSize}
      formGroupClassName={formGroupClassName}
      labelDefinationKey={labelDefinationKey}
      titleGuidText={titleGuidText}
      isEditable={isEditable}
      labelClassName={classnames("rule-action-label", labelClassName)}
      contentClassName={classnames("rule-action-content", contentClassName)}
      errorClassName={errorClassName}
    >
      {customRenderer ? (
        customRenderer({
          typeKey,
          value,
          renderActionDropdown,
          renderActionComponents,
          renderRemoveButton,
          renderAndLabel,
          renderAddButton,
        })
      ) : (
        <>
          {contentTopRender ? contentTopRender() : null}
          {value.map((val, index) => {
            const typeValue = _get(val, typeKey);
            return (
              <>
                <div key={typeValue} className={classnames("rule-action-box", { "read-only": !isEditable })}>
                  {renderActionDropdown({ index, val })}
                  {renderActionComponents({ val, index })}
                  {enableDuplicateAction ? renderDuplicateButton({ index }) : null}
                  {renderRemoveButton({ index })}
                </div>
                {value.length > 1 && index < value.length - 1 ? renderAndLabel() : null}
              </>
            );
          })}
          {renderAddButton()}
        </>
      )}
    </FormWrapper>
  );
};

BaseAction.defaultProps = {
  typeKey: "type",
  enableDuplicateAction: false,
  labelColumnsSize: 12,
  doNotSetDefaultAction: false,
  enableOptionGroup: false,
  showIndexInTitle: true,
  showAndLabel: true,
  enableDescription: false,
  minAction: 1,
  allowSameActions: false,
  actionDropdownProps: {},
  autoSetFirst: true,
  contentTopRender: null,
  commonInitValueToAdd: {},
};

export default BaseAction;
