import React, { useMemo, useEffect } from "react";
import _get from "lodash/get";
import _times from "lodash/times";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";

import classnames from "classnames";

import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { computeQuery } from "@onlinesales-ai/util-methods-v2";
import { Button } from "@onlinesales-ai/button-v2";
import { EmptyMessage } from "@onlinesales-ai/message-card-v2";

import FormWrapper from "../../FormWrapper";

import "./index.less";

const MultiFormSubComponent = ({
  dataKey,
  formValues,
  formErrors,
  onChange,
  onError,
  renderComponent,
  initialComponentValue,
  renderCustomAddComponent,
  renderCustomValueComponent,
  renderCustomCommonComponent,
  title,
  infoMessage,
  validations,
  commonComponentToShow: pCommonComponentToShow,
  commonComponentConfig,
  minCount,
  maxCount,
  showConfirmationModal,
  resetConfirmationModal,
  showTitle,
  isEditable,
  entity,
  customRenderer,
  emptyRenderMessage,
  containerClass,
  ...props
}) => {
  const value = useMemo(() => {
    return _get(formValues, dataKey) || [];
  }, [dataKey, formValues]);

  const showRemoveButton = useMemo(() => {
    return value?.length > minCount;
  }, [value, minCount]);

  const showAddComponent = useMemo(() => {
    return value?.length < maxCount;
  }, [value, maxCount]);

  const commonComponentToShow = useMemo(() => {
    if (pCommonComponentToShow) {
      return pCommonComponentToShow;
    }
    return _sortBy(
      Object.keys(commonComponentConfig)?.filter((k) => commonComponentConfig[k]?.isShow) || [],
      (key) => commonComponentConfig?.[key]?.order,
    );
  }, [commonComponentConfig, pCommonComponentToShow]);

  useEffect(() => {
    if (minCount && value.length < minCount) {
      const intialMinComponents = [];

      _times(minCount - (value.length || 0), () =>
        intialMinComponents.push(_cloneDeep(initialComponentValue) || {}),
      );

      onChange({
        [dataKey]: intialMinComponents,
      });
    }
  }, [minCount]);

  const validate = () => {
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value?.length) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "evenLength":
          {
            if ((value?.length || 0) % 2 !== 0) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title?.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  useEffect(() => {
    validate();
  }, [value]);

  const onClickAdd = () => {
    onChange({
      [dataKey]: [...value, _cloneDeep(initialComponentValue) || {}],
    });
  };

  const onClickRemove = (index) => {
    let showConfirmation = false;

    commonComponentToShow.forEach((comp) => {
      const component = commonComponentConfig[comp];
      const subDataKey = component?.props?.dataKey;

      const finalDataKey = `${dataKey}[${index}].${subDataKey}`;

      if (_get(formValues, finalDataKey)) {
        showConfirmation = true;
      }
    });

    const performDeleteActions = () => {
      onChange({ [dataKey]: value.filter((item, i) => i !== index) });
      onError(
        Object.keys(formErrors).reduce((memo, key) => {
          if (key.includes(`${dataKey}[${index}].`)) {
            memo[key] = null;
          }
          return memo;
        }, {}),
      );

      if (index !== value.length - 1) {
        onError(
          Object.keys(formErrors).reduce((memo, key) => {
            if (key.includes(`${dataKey}[${value.length - 1}].`)) {
              memo[key] = null;
            }
            return memo;
          }, {}),
        );
      }
    };

    if (showConfirmation) {
      showConfirmationModal({
        isShow: true,
        title: "Are you sure you want delete, your data will be lost.",
        rightBtnText: "No",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          performDeleteActions();
          resetConfirmationModal();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      performDeleteActions();
    }
  };

  const renderAddComponent = () => {
    if (isEditable && showAddComponent) {
      return renderCustomAddComponent ? (
        renderCustomAddComponent({ onClickAdd })
      ) : (
        <Button className="add-component-button" onClick={onClickAdd}>Add</Button>
      );
    } else if (!isEditable && (value || [])?.length === 0 && emptyRenderMessage) {
      return <EmptyMessage>{emptyRenderMessage}</EmptyMessage>;
    }
  };

  const renderInnerComponent = ({ component, index, props: extraProps }) => {
    const resetValueOnChange = (component.props?.resetValueOnChange || []).map((obj) => {
      return {
        ...obj,
        key: obj.processWithIndex ? `${dataKey}[${index}].${obj.key}` : obj.key,
        processWithIndex: true,
      };
    });

    const resetErrorsOnChange = (component.props?.resetErrorsOnChange || []).map((obj) => {
      return {
        ...obj,
        key: obj.processWithIndex ? `${dataKey}[${index}].${obj.key}` : obj.key,
        processWithIndex: true,
      };
    });

    if (resetValueOnChange?.length > 0 && component.props) {
      component.props = {
        ...component.props,
        resetValueOnChange,
      };
    }

    if (resetErrorsOnChange?.length > 0 && component.props) {
      component.props = {
        ...component.props,
        resetErrorsOnChange,
      };
    }

    return renderComponent({
      component,
      props: extraProps,
      overrides: {
        dataKey: `${dataKey}[${index}].${component.props.dataKey}`,
        dataKeyPrefix: `${dataKey}[${index}].`,
      },
    });
  };

  const renderCommonCommponent = ({ val, index }) => {
    if (renderCustomCommonComponent) {
      const shouldRender = (id) => {
        const component = commonComponentToShow.includes(id);

        if (component) {
          const config = commonComponentConfig[id];

          if (config.showIf) {
            return computeQuery({ query: config.showIf, value, componentValue: val, values: val });
          }
          return true;
        }

        return false;
      };

      const renderFormComponent = (id, { props: extraProps } = {}) => {
        if (!shouldRender(id)) {
          return null;
        }

        const config = commonComponentConfig[id];

        return renderInnerComponent({
          component: { ...config, alreadyCheckedShowIf: true },
          index,
          props: extraProps,
        });
      };

      return renderCustomCommonComponent({
        val,
        index,
        shouldRender,
        renderFormComponent,
      });
    }

    return (
      <div className="common-comp-wrapper d-flex-column width-flex-1">
        {commonComponentToShow?.map?.((key) => {
          const component = commonComponentConfig[key];

          return renderInnerComponent({ component, index });
        })}
      </div>
    );
  };

  const renderRemoveButton = ({ index }) => {
    if (!(isEditable && showRemoveButton)) {
      return null;
    }

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClickRemove(index);
        }}
        className="delete-icon pendo_track_multiform_sub_component_delete_button"
      >
        <Text size="large" type="primary">
          <span className="icon icon-trash-2 cursor-pointer" />
        </Text>
      </div>
    );
  };

  const renderValues = () => {
    return value.map((val, index) => {
      if (renderCustomValueComponent) {
        return renderCustomValueComponent({
          val,
          index,
          renderRemoveButton: () => renderRemoveButton({ index }),
          renderCommonCommponent: () => renderCommonCommponent({ val, index }),
        });
      }

      return (
        <div className="d-flex-column justify-content-between position-relative component-wrapper-outer" key={index}>
          {showTitle && (
            <Text weight="semiBold" type="primary" className="pt-1 pb-4 btnheight">
              {entity} {index + 1}
            </Text>
          )}
          {renderCommonCommponent({ val, index })}
          {renderRemoveButton({ index })}
        </div>
      );
    });
  };

  return (
    <FormWrapper
      title={title}
      dataKey={dataKey}
      formValues={formValues}
      labelColumns={0}
      error={formErrors[dataKey]}
      hasError={props.showErrors && !!formErrors[dataKey]}
      {...props}
    >
      {typeof customRenderer === "function" ? (
        customRenderer({
          renderAddComponent,
          renderValues,
        })
      ) : (
        <div
          className={classnames("dyanamic-upload-sub-wrapper", containerClass, {
            "read-only": !isEditable,
          })}
        >
          {infoMessage && (
            <Text className="mb-3" type="primary" size="small">
              {infoMessage}
            </Text>
          )}
          <div className="dyanamic-upload-wrapper-inner d-wrap">
            {renderValues()}
            {renderAddComponent()}
          </div>
        </div>
      )}
    </FormWrapper>
  );
};

MultiFormSubComponent.defaultProps = {
  initialComponentValue: {},
  showTitle: false,
  validations: [],
  entity: "Component",
};

export default OSHOCWithUtilities(MultiFormSubComponent);
