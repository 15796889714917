import React, { useState } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./index.less";

const WithTooltip = ({ children = null, tooltipbg, tooltipClass = "", title, placement = "top", overlayProps = {}, tooltipProps = {}, enableBoxShadow, isMobile, defaultShow, popperConfig }) => {
  const [timeStamp] = useState(new Date().getTime());
  if (!title) {
    return children;
  }

  return (
    <OverlayTrigger
      key={placement}
      // shouldUpdatePosition
      defaultShow={defaultShow}
      placement={placement}
      trigger={isMobile ? ["focus", "click", "focus"] : ["hover", "hover"]}
      rootClose
      popperConfig={popperConfig}
      overlay={(
        <Tooltip
          bsPrefix={`${tooltipClass} os-reusable-tooltip ${tooltipbg} ${enableBoxShadow && "box-shadow-only"} tooltip`}
          id={`tooltip-${timeStamp}`}
          {...tooltipProps}
        >
          {typeof title === "string" ? <Trans>{title}</Trans> : title}
        </Tooltip>
      )}
      {...overlayProps}
    >
      {children}
    </OverlayTrigger>
  );
};

const mapStateToProps = (state) => {
  const {
    isMobile,
  } = state.Application || {};

  return {
    isMobile,
  };
};

WithTooltip.defaultProps = {
  tooltipbg: "white-bg", // tooltipbg = white-bg | default-bg | error-bg | blue-bg
  enableBoxShadow: false,
  defaultShow: false,
};

export default connect(mapStateToProps)(WithTooltip);
