import { JSONPath } from "jsonpath-plus";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _cloneDeepWith from "lodash/cloneDeepWith";
import _uniq from "lodash/uniq";
import { checkIfUserIsInternal, checkIfUserIsPartnerInternal, customMergeOS } from "@onlinesales-ai/util-methods-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

import {
  extractDomainConfigOverrideFromOnboarding,
  applyDomainConfigOverrides,
  conversionAttributionColumnMapping,
} from "@onlinesales-ai/domain-config-v2";

import { setIsInternalUser, setIsInternalUserViewMode, setIsPartnerInternalUser } from "../application";

import Types from "./types";

const conversionAttributionTypes = {
  LEAD_TIME: "LEAD_TIME",
  CLICK_TIME: "CLICK_TIME",
};

export const prepareDomainConfig = ({
  getState,
  dispatch,
  config,
  onBoardingData,
  applicationOverrides = {},
  objectToCustomMergeWithOuput = {},
}) => {
  const state = getState();
  const ApplicationData = {
    ...(state.Application || {}),
    ...applicationOverrides,
  };

  const { userInfo, isAllClientsSelected, clients = [], agencySettings } = ApplicationData;
  const onBoarding = onBoardingData || state.OnBoarding?.data || {};
  let isInternalUser = false;
  let isRealInternalUser = false;
  let isPartnerInternalUser = false;

  const replaceAccessorWithClickAccessor = (config) => {
    const { metricsMasterConfig } = _cloneDeep(config) || {};

    Object.keys(metricsMasterConfig).forEach((metric) => {
      if (metricsMasterConfig?.[metric]?.clickAccessor) {
        metricsMasterConfig[metric].accessor = metricsMasterConfig[metric].clickAccessor;
        metricsMasterConfig[metric].id = metricsMasterConfig[metric].clickAccessor;
      }
    });

    return { ...config, metricsMasterConfig };
  };

  const replacePlaceHolderWithCanonicalColumns = (
    config,
    conversionAttributionType = conversionAttributionTypes.LEAD_TIME,
  ) => {
    const placeholderRegex = /#__\w+__#/g;

    const configWithReplacedPlacedholder = _cloneDeepWith(config, (value) => {
      if (typeof value === "string" && placeholderRegex.test(value)) {
        const placeholder = value.match(placeholderRegex)[0];
        let canonicalColumn = placeholder;

        if (conversionAttributionType === conversionAttributionTypes.CLICK_TIME) {
          canonicalColumn =
            conversionAttributionColumnMapping?.[placeholder]?.clickTimeCanonicalColumn;
        }

        if (conversionAttributionType === conversionAttributionTypes.LEAD_TIME) {
          canonicalColumn =
            conversionAttributionColumnMapping?.[placeholder]?.leadTimeCanonicalColumn;
        }
        return canonicalColumn;
      }
    });

    const placeholdersRemaining = _uniq(
      JSON.stringify(configWithReplacedPlacedholder)?.match(placeholderRegex),
    );
    if (placeholdersRemaining?.length) {
      triggerMonitor("SEV2", "CONVERSION_ATTRIBUTION_COLUMN_MAPPING", { "missing_placeholders": placeholdersRemaining });
    }

    return configWithReplacedPlacedholder;
  };

  if (
    agencySettings?.partnerInternalUserDomains?.length > 0 ||
    agencySettings?.internalUserDomains?.length > 0
  ) {
    isInternalUser = checkIfUserIsInternal(userInfo, config);
    isRealInternalUser = checkIfUserIsInternal(userInfo, config, false);
    isPartnerInternalUser = checkIfUserIsPartnerInternal(userInfo, config);
    dispatch(setIsInternalUser(isInternalUser, isRealInternalUser));
    dispatch(setIsPartnerInternalUser(isPartnerInternalUser));
    dispatch(setIsInternalUserViewMode(isInternalUser || isPartnerInternalUser));
  }

  if (
    config?.platformType === "PULSE" &&
    agencySettings?.conversionAttributionType === conversionAttributionTypes.CLICK_TIME
  ) {
    config = replaceAccessorWithClickAccessor(config);
  }

  if (config?.platformType === "SELLER" && agencySettings?.conversionAttributionType) {
    config = replacePlaceHolderWithCanonicalColumns(
      config,
      agencySettings?.conversionAttributionType,
    );

    if (
      config?.swapAccessorForConversionAttribution &&
      agencySettings?.conversionAttributionType === conversionAttributionTypes.CLICK_TIME
    ) {
      config = replaceAccessorWithClickAccessor(config);
    }
  }

  const domainConfigOverrides = extractDomainConfigOverrideFromOnboarding(
    userInfo,
    onBoarding,
    config,
  );

  const keysToBeProceed = [];

  if (config.internalUserOverride && isInternalUser) {
    keysToBeProceed.push(config?.internalUserOverride);
    domainConfigOverrides.push(config?.internalUserOverride);
  }

  if (config.partnerInternalUserOverride && isPartnerInternalUser) {
    keysToBeProceed.push(config?.partnerInternalUserOverride);
    domainConfigOverrides.push(config?.partnerInternalUserOverride);
  }

  if (config.allClientsOverride && isAllClientsSelected) {
    domainConfigOverrides.push(config.allClientsOverride);
  }

  if (config.singleClientOverride && clients.length === 1) {
    domainConfigOverrides.push(config.singleClientOverride);
  }

  if (config.userOverrides) {
    config.userOverrides.forEach((overrideInfo) => {
      if (
        (overrideInfo.isApplyForAllClients && !isAllClientsSelected) ||
        (!overrideInfo.isApplyForAllClients && isAllClientsSelected)
      ) {
        return;
      }

      if (overrideInfo?.jsonPath?.path) {
        const idsData = JSONPath(overrideInfo.jsonPath.path, state);
        if (idsData?.length) {
          overrideInfo[overrideInfo.jsonPath.key] = idsData[0];
        }
      }

      if (
        overrideInfo?.overrideKey &&
        (overrideInfo?.ids?.includes(userInfo?.id) ||
          (overrideInfo.isApplyForInternalUsers && isInternalUser))
      ) {
        domainConfigOverrides.push(overrideInfo.overrideKey);
      }
    });
  }

  if (
    agencySettings?.conversionAttributionType === conversionAttributionTypes.LEAD_TIME &&
    config?.leadTimeConfigOverride
  ) {
    keysToBeProceed.push("leadTimeConfigOverride");
    domainConfigOverrides.push("leadTimeConfigOverride");
    config.configOverrides.leadTimeConfigOverride = config?.leadTimeConfigOverride;
  }

  if (
    agencySettings?.conversionAttributionType === conversionAttributionTypes.CLICK_TIME &&
    config?.clickTimeConfigOverride
  ) {
    keysToBeProceed.push("clickTimeConfigOverride");
    domainConfigOverrides.push("clickTimeConfigOverride");
    config.configOverrides.clickTimeConfigOverride = config?.clickTimeConfigOverride;
  }

  config = applyDomainConfigOverrides(config, domainConfigOverrides, keysToBeProceed);
  // Support for dynamicOverrides, Change this position to change the priority
  domainConfigOverrides.push("dynamicOverrides");

  config = applyDomainConfigOverrides(config, domainConfigOverrides, keysToBeProceed, {
    State: state,
  });
  if (!_isEmpty(objectToCustomMergeWithOuput)) {
    return customMergeOS({}, config, objectToCustomMergeWithOuput);
  } else {
    return config;
  }
};

export const setDomainConfig = (config = {}, onBoardingData, objectToCustomMergeWithOuput) => {
  return (dispatch, getState) => {
    config = prepareDomainConfig({
      getState,
      dispatch,
      config,
      onBoardingData,
      objectToCustomMergeWithOuput,
    });

    dispatch({
      type: Types.SET_DOMAIN_CONFIG,
      config,
    });

    // Specific handling for this flag for hygiene notification,
    dispatch({
      type: Types.SET_IS_TOPUP_ENABLED_IN_APPLICATION,
      isEnabled: config?.commonConfigs?.walletBalanceV2?.enableTopup || false,
    });

    return config;
  };
};
