import { Hades, InceptionService } from "@onlinesales-ai/services-v2";
import { processEntityMetadataFromAPI } from "@onlinesales-ai/ott-common-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import { routes } from "src/utilities/constants";
import {
  // upsertEntityMetadata,
  redirectUrl,
  parseEntityData,
  updateEntityInfo,
  updateEntityMetadataList,
  updateEntityBillingInfo,
} from "src/store/application";

import types from "./types";

export const setOnboardingData = (onBoardingData) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.SET_ONBOARDING_DETAILS,
      data: onBoardingData,
      domainConfig: state.DomainConfig,
    });
  };
};

export const setOnboardingDataFetchInProgress = (isLoading) => ({
  type: types.SET_ONBOARDING_FETCH_STATE,
  isLoading,
});

export const fetchOnBoardingData = (updateDomainConfig) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedEntityId, selectedEntityType } = state.Application;

    dispatch(setOnboardingDataFetchInProgress(true));

    const payload = {
      entityId: selectedEntityId,
      entityType: selectedEntityType,
      selectors: [
        // "metadata.customLabel2",
      ],
    };

    try {
      const data = await InceptionService.getEntityAndBillingData(payload, "onboarding_status_fetch");
      const metadata = processEntityMetadataFromAPI(data?.response?.entityData?.metadata);
      const { entityInfoToUpdate, entityMedataToUpdate } = parseEntityData([
        { ...data?.response?.entityData, entityBillingInfo: data?.response?.billingInfo },
      ]);

      const { isAdmin, isRefundBlocked } = data?.response?.entityData || {};

      dispatch(updateEntityBillingInfo(data?.response?.billingInfo, { isAdmin, isRefundBlocked }));
      dispatch(setOnboardingData(metadata?.onboardingData || {}));
      // dispatch(upsertEntityMetadata(`${selectedEntityType}_${selectedEntityId}`, metadata));
      dispatch(updateEntityInfo(entityInfoToUpdate));
      dispatch(
        updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
      );
      updateDomainConfig();
    } catch (err) {
      if (err.isAborted) {
        return;
      }
      uiAPIMonitor("SEV2", "OTT_APP_LEVEL_CONFIG_DATA_ERROR", { error: err });
      const errorMsg = err?.data?.exception?.error?.message;
      if (
        errorMsg === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes?.LOGIN?.path));
      } else {
        dispatch(redirectUrl(routes?.DOWNTIME?.path));
      }
    } finally {
      dispatch(setOnboardingDataFetchInProgress(false));
    }
  };
};
