import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { Trans } from "react-i18next";
import _cloneDeep from "lodash/cloneDeep";

import { useMemoCompare, useOnClickOutside } from "@onlinesales-ai/util-methods-v2";
import { Input } from "@onlinesales-ai/input-v2";
import CollapsibleCard from "@onlinesales-ai/collapsible-card-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text } from "@onlinesales-ai/label-v2";

import { getCaretPosition } from "./utils";

import "./index.less";

const DynamicField = ({
  onSelect,
  options,
  children,
  valueTemplate,
  hasShiftKey,
  triggerChar,
  showSearch,
  containerClass,
}) => {
  const inputRef = useRef();
  const wrapperRef = useRef();
  const searchRef = useRef();
  const selectionPosition = useRef();

  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const closeShowOptions = () => {
    const pos = selectionPosition.current;
    if (pos) {
      inputRef.current.setSelectionRange(pos, pos);
    }
    setSearchValue("");
    setShowOptions(false);
  };

  const listRef = useOnClickOutside(() => {
    closeShowOptions();
  });

  const renderOptions = () => {
    const pos = getCaretPosition(inputRef.current);

    listRef.current.style.top = `${pos.y}px`;
    listRef.current.style.left = `${pos.x + 5}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      const onKeyup = (event) => {
        if ((hasShiftKey ? event.shiftKey : true) && event.key === triggerChar) {
          renderOptions();
          setShowOptions(true);
          event.stopPropagation();
        } else if (showOptions) {
          closeShowOptions();
        }
      };

      inputRef.current.addEventListener("keyup", onKeyup);
      const observerInputRefValue = inputRef.current;
      return () => {
        observerInputRefValue.removeEventListener("keyup", onKeyup);
      };
    }

    return () => {};
  }, [showOptions]);

  useEffect(() => {
    if (showOptions && searchRef.current) {
      selectionPosition.current = inputRef.current.selectionStart;
      searchRef.current.focus();
    }
  }, [showOptions]);

  const onClickOption = (value) => {
    closeShowOptions();
    if (onSelect) {
      let selectedValue = value;

      if (valueTemplate) {
        selectedValue = valueTemplate.replace(/VALUE/g, selectedValue);
      }

      onSelect({
        value: selectedValue,
        startPosition: inputRef.current.selectionStart,
      });
    }
  };

  const filteredOptions = useMemo(() => {
    const newOptions = [];
    options.forEach((option) => {
      let result = false;
      result = option.label?.toLowerCase().includes(searchValue?.toLowerCase());
      if (!result && option.options?.length) {
        const localOptions = (option.options || []).filter(({ label }) => {
          return label?.toLowerCase().includes(searchValue?.toLowerCase());
        });
        if (localOptions.length > 0) {
          newOptions.push({ ...option, options: localOptions });
        }
      }
      if (result) {
        newOptions.push(option);
      }
    });
    return newOptions;
  }, [options, searchValue]);

  const renderMenuItemContent = (option) => {
    return (
      <WithTooltip title={option.tooltip} overlayProps={{ delay: { show: 350 } }}>
        <div
          className={classnames("dynamic-field-popover-menu-item", option.className, {
            disabled: option.disabled,
          })}
          onClick={(e) => {
            if (!option.disabled) {
              e.stopPropagation();
              onClickOption(option.value);
            }
          }}
        >
          {option.iconClass && (
            <div className="df-menu-item-left">
              <span className={`icon ${option.iconClass}`} />
            </div>
          )}
          <div className="df-menu-item-right">
            {option.label && (
              <div className="df-menu-item-title">
                <Trans>{option.label}</Trans>
              </div>
            )}
            {option.description && (
              <div className="df-menu-item-description">
                <Trans>{option.description}</Trans>
              </div>
            )}
          </div>
        </div>
      </WithTooltip>
    );
  };

  const renderMenu = useMemo(() => {
    return filteredOptions.map((option, index) => {
      if (option?.options) {
        return (
          <CollapsibleCard
            cardHeader={
              <div className="card-title justify-content-between">
                <Text size="medium">{option.label}</Text>
                <span
                  className={`icon icon-caret-down ${false ? "icon-dropdown" : "icon-arrow-1-up"}`}
                />
              </div>
            }
            controlled={false}
            containerClass={classnames("m-0 rounded-0", {
              "border-top-0 border-right-0 border-left-0": !(index === filteredOptions.length - 1),
              "border-0": index === filteredOptions.length - 1,
            })}
            cardHeaderClass="rounded-0 p10"
            cardBodyClass="p-0"
          >
            {option?.options?.map((subOption) => {
              return <>{renderMenuItemContent(subOption)}</>;
            })}
          </CollapsibleCard>
        );
      }
      return renderMenuItemContent(option);
    });
  }, [filteredOptions]);

  return (
    <div ref={wrapperRef} className={classnames("dynamic-field-wrapper", containerClass)}>
      <ul ref={listRef} className={`field-dropdown ${showOptions ? "show" : ""}`}>
        {showSearch ? (
          <li className="search-input-li">
            <Input
              ref={searchRef}
              value={searchValue}
              onChange={setSearchValue}
              className="dynamic-search-input"
              prefix={<span className="icon icon-search-2" />}
            />
          </li>
        ) : null}
        <ul className="options-list">
          {filteredOptions.length === 0 ? (
            <Text className="d-center p10 gap-5 nowrap-text" type="primary">
              No options found for <Text weight="semiBold">{searchValue}</Text>
            </Text>
          ) : (
            <>
              {renderMenu}
            </>
          )}
        </ul>
      </ul>
      {children({ inputRef })}
    </div>
  );
};

DynamicField.valueReplacer = (text, { value, startPosition }) => {
  if (!text) {
    return value;
  }

  const newText = text.substring(0, startPosition - 1) + text.substring(startPosition);

  return newText.substring(0, startPosition - 1) + value + newText.substring(startPosition - 1);
};

DynamicField.defaultProps = {
  options: [],
  valueTemplate: "VALUE",
  hasShiftKey: true,
  triggerChar: "^",
  showSearch: true,
};

export default DynamicField;
