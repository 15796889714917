import React, { useMemo } from "react";
import _isEmpty from "lodash/isEmpty";
import { JSONPath } from "jsonpath-plus";

import { customMergeOS } from "./customMergeOS";

export const configHoc = (Component, options = {}) => {
  return React.forwardRef((props, ref) => {
    const {
      defaultConfigProps,
      configProps,
      commonConfigProps,
      componentToShow,
      componentConfig,
      componentList: pComponentList,
      tableHeaderToShow,
      tableHeaderConfig,
      mobileTableHeaderToShow,
      mobileTableHeaderConfig,
      columns: pColumns,
      mobileColumns: pMobileColumns,
      convertToArray: pConvertToArray,
      convertToArrayConfig,
    } = props;

    const convertToArray = useMemo(() => {
      if (pConvertToArray && typeof pConvertToArray === "object") {
        return { ...pConvertToArray };
      } else if (convertToArrayConfig && typeof convertToArrayConfig === "object") {
        return { ...convertToArrayConfig };
      }
      return null;
    }, [pConvertToArray, convertToArrayConfig]);

    const hocProps = useMemo(() => {
      return customMergeOS({}, defaultConfigProps, commonConfigProps, configProps);
    }, [defaultConfigProps, configProps, commonConfigProps]);

    const getPropsWithConvertedArray = useMemo(() => {
      if (convertToArray?.paths?.length > 0) {
        const newProps = { ...props };
        convertToArray.paths.map((path) => {
          JSONPath({
            path,
            json: newProps,
            wrap: false,
            callback: (resultObj, resultType, { parent, parentProperty }) => {
              let newArrayValue = [];
              if (typeof resultObj === "object" && !Array.isArray(resultObj)) {
                const keyArray = convertToArray.dataToCheck?.length
                  ? convertToArray.dataToCheck
                  : Object.keys(resultObj);

                keyArray.map((key) => {
                  const obj = resultObj?.[key] || {};
                  if (
                    (convertToArray.validateWithKey && obj[convertToArray.validateWithKey]) ||
                    !convertToArray.validateWithKey
                  ) {
                    if (convertToArray.addKeysToArray) {
                      newArrayValue.push(key);
                    } else {
                      newArrayValue.push(obj);
                    }
                  }
                });
              } else {
                newArrayValue = resultObj;
              }
              parent[parentProperty] = newArrayValue;
            },
          });
        });
        return newProps;
      }
      return {};
    }, [props]);

    const getComponentList = (pComponentToShow, pComponentConfig) => {
      if (pComponentToShow?.length) {
        const retnData = [];
        pComponentToShow.forEach((key) => {
          const c = pComponentConfig?.[key] || {};
          if (c.isShow === false) {
            return;
          }

          if (c.isFormArray) {
            c.props.componentList = getComponentList(
              c.props.componentToShow,
              c.props.componentConfig,
            );
          }

          retnData.push(c);
        });
        return retnData;
      }

      return [];
    };

    const getHeaderList = (pTableHeaderToShow, pTableHeaderConfig) => {
      if (pTableHeaderToShow?.length) {
        const newHeaderToShow = [];
        pTableHeaderToShow.forEach((key) => {
          if (!_isEmpty(pTableHeaderConfig?.[key])) {
            newHeaderToShow.push(pTableHeaderConfig?.[key]);
          }
        });
        return newHeaderToShow;
      }

      return [];
    };

    const formProps = useMemo(() => {
      if (!options?.isForm) {
        return {};
      }
      return {
        componentList: pComponentList || getComponentList(componentToShow, componentConfig),
      };
    }, [pComponentList, componentToShow, componentConfig]);

    const tableProps = useMemo(() => {
      if (!options?.isTable) {
        return {};
      }

      return {
        columns: pColumns || getHeaderList(tableHeaderToShow, tableHeaderConfig),
        mobileColumns:
          pMobileColumns || getHeaderList(mobileTableHeaderToShow, mobileTableHeaderConfig),
      };
    }, [
      pColumns,
      tableHeaderToShow,
      tableHeaderConfig,
      mobileTableHeaderToShow,
      mobileTableHeaderConfig,
    ]);

    return (
      <Component
        ref={ref}
        {...props}
        {...getPropsWithConvertedArray}
        {...formProps}
        {...tableProps}
        {...hocProps}
      />
    );
  });
};
