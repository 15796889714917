import React from "react";

import classnames from "classnames";

import BaseAction from "../baseAction";

import "./index.less";

const RuleCondition = ({ customRenderer: customRendererProp, formGroupClassName, ...props }) => {
  const customRenderer = ({
    typeKey,
    value,
    renderActionDropdown,
    renderActionComponents,
    renderRemoveButton,
    renderAndLabel,
    renderAddButton,
  }) => {
    if (typeof customRendererProp === "function") {
      return customRendererProp(
        typeKey,
        value,
        renderActionDropdown,
        renderActionComponents,
        renderRemoveButton,
        renderAndLabel,
        renderAddButton,
      );
    }
    return (
      <>
        {value.map((val, index) => {
          return (
            <div key={val[typeKey]} className="rule-action-box">
              <div className="and-label-wrapper">{index > 0 ? renderAndLabel() : null}</div>
              <div className="components-wrapper">
                {renderActionDropdown({ index, val })}
                {renderActionComponents({ val, index })}
                {renderRemoveButton({ index })}
              </div>
            </div>
          );
        })}
        {renderAddButton()}
      </>
    );
  };

  return (
    <BaseAction
      actionTitle="Condition"
      actionTitleColumn={5}
      {...props}
      formGroupClassName={classnames("rule-condition-group-component", formGroupClassName)}
      customRenderer={customRenderer}
    />
  );
};

export default RuleCondition;
